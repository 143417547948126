import React from "react"
import ImpactfulImg from "../../images/landing-pages/impactful-ss-img.png"
import TextImage from "../../components/landing-pages/blocks/text-image.js"

class ImpactfulSection extends React.Component {
  render() {
    return (
      <section className="impactful-ss">
        <div className="ld-container">
          <TextImage imgUrl={ImpactfulImg}>
            <p className="large-size">
              Next&Co are a <b>full-service media</b> and{" "}
              <b>digital marketing agency</b> that combine our suite of
              proprietary tech tools and the thinking of our people to{" "}
              <b>maximize performance.</b>
            </p>
            <p>
              Our paid media management services are built to implement{" "}
              <b>audience-driven, omni-channel strategies</b> that work across
              the customer journey, and measure their{" "}
              <b>impact on your brand</b>, as apposed to vanity marketing
              metrics.
            </p>
          </TextImage>
        </div>
      </section>
    )
  }
}

export default ImpactfulSection
