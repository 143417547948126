import React from "react"
import Adobe from "../../images/landing-pages/partners/Adobe.png"
import Youtube from "../../images/landing-pages/partners/Youtube.png"
import Facebook from "../../images/landing-pages/partners/Facebook.png"
import Yahoo from "../../images/landing-pages/partners/Yahoo.png"
import Arn from "../../images/landing-pages/partners/Arn.png"
import Bing from "../../images/landing-pages/partners/Bing.png"
import Channel from "../../images/landing-pages/partners/Channel.png"
import Microsoft from "../../images/landing-pages/partners/Microsoft.png"
import Foxtel from "../../images/landing-pages/partners/Foxtel.png"
import Now9 from "../../images/landing-pages/partners/Now9.png"
import Ghost from "../../images/landing-pages/partners/Ghost.png"
import GoogleAds from "../../images/landing-pages/partners/GoogleAds.png"
import Instagram from "../../images/landing-pages/partners/Instagram.png"
import JCDecaux from "../../images/landing-pages/partners/JCDecaux.png"
import Linkedin from "../../images/landing-pages/partners/Linkedin.png"
import NewsCorps from "../../images/landing-pages/partners/NewsCorps.png"
import Plus7 from "../../images/landing-pages/partners/7Plus.png"
import OoH from "../../images/landing-pages/partners/OoH.png"
import Reddit from "../../images/landing-pages/partners/Reddit.png"
import RuralPressLimited from "../../images/landing-pages/partners/RuralPressLimited.png"
import Spotify from "../../images/landing-pages/partners/Spotify.png"
import Taboola from "../../images/landing-pages/partners/Taboola.png"
import TheTradeDesk from "../../images/landing-pages/partners/TheTradeDesk.png"
import TikTok from "../../images/landing-pages/partners/TikTok.png"
import Twitch from "../../images/landing-pages/partners/Twitch.png"

class PartnersSection extends React.Component {
  render() {
    return (
      <section className="partners-ss">
        <div className="ld-container">
          <div className="partners-wrap">
            <div className="description">
              We buy across all <b>digital and traditional channels</b>,
              connecting a brands media plan from awareness all the way through
              to final <b>conversion and beyond.</b>
            </div>
            <div className="partners-lst">
              <img src={Adobe} alt="Adobe" />
              <img src={Youtube} alt="Youtube" />
              <img src={Facebook} alt="Facebook" />
              <img src={Yahoo} alt="Yahoo" />
              <img src={Arn} alt="Arn" />
              <img src={Bing} alt="Bing" />
              <img src={Channel} alt="Channel" />
              <img src={Microsoft} alt="Microsoft" />
              <img src={Foxtel} alt="Foxtel" />
              <img src={Now9} alt="9Now" />
              <img src={Ghost} alt="Ghost" />
              <img src={GoogleAds} alt="Google Ads" />
              <img src={Instagram} alt="Instagram" />
              <img src={JCDecaux} alt="JCDecaux" />
              <img src={Linkedin} alt="Linkedin" />
              <img src={NewsCorps} alt="News Corps" />
              <img src={Plus7} alt="7Plus" />
              <img src={OoH} alt="OoH" />
              <img src={Reddit} alt="Reddit" />
              <img src={RuralPressLimited} alt="Rural Press Limited" />
              <img src={Spotify} alt="Spotify" />
              <img src={Taboola} alt="Taboola" />
              <img src={TheTradeDesk} alt="The Trade Desk" />
              <img src={TikTok} alt="TikTok" />
              <img src={Twitch} alt="Twitch" />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default PartnersSection
