import React from "react"
import CaseStudyItem from "../../components/landing-pages/blocks/case-study-item.js"
import CaseStudyImg1 from "../../images/landing-pages/case-study-1.jpeg"
import CaseStudyImg2 from "../../images/landing-pages/case-study-2.jpeg"
import CaseStudyImg3 from "../../images/landing-pages/case-study-3.jpeg"
import CaseStudyImg4 from "../../images/landing-pages/case-study-4.jpeg"

class CaseStudyOrangeSection extends React.Component {
  render() {
    const caseStudyLst = [
      {
        imgUrl: CaseStudyImg1,
        title: "Nutrien (NPay Campaign Launch)",
        infoLst: [
          {
            countNum: 1500,
            prefix: "",
            suffix: "",
            subHeading: "new customers driven quarterly",
          },
          {
            countNum: 50,
            prefix: "$",
            suffix: "M",
            subHeading: "in revenue driven",
          },
          {
            countNum: 269,
            prefix: "",
            suffix: "%",
            subHeading: "Average increase in QoQ conversion",
          },
          {
            countNum: 1500,
            prefix: "",
            suffix: "",
            subHeading: "new customers driven quarterly",
          },
        ],
      },
      {
        imgUrl: CaseStudyImg2,
        title: "BitDefender",
        infoLst: [
          {
            countNum: 25,
            prefix: "",
            suffix: "%",
            subHeading: "Increase in enquiries",
          },
          {
            countNum: 33,
            prefix: "",
            suffix: "%",
            subHeading: "Decrease in cost per lead",
          },
          {
            countNum: 500,
            prefix: "",
            suffix: "%",
            subHeading: "Increase in Ad Recall Lift",
          },
          {
            countNum: 87,
            prefix: "",
            suffix: "%",
            subHeading: "Increase in website traffic",
          },
        ],
      },
      {
        imgUrl: CaseStudyImg3,
        title: "Palace Entertainment / Raging Waters",
        infoLst: [
          {
            countNum: 15,
            prefix: "$",
            suffix: "m",
            subHeading: "Revenue Increase",
          },
          {
            countNum: 423,
            prefix: "",
            suffix: "%",
            subHeading: "Increase In YoY Footfall",
          },
          {
            countNum: 352,
            prefix: "",
            suffix: "%",
            subHeading: "Increase In Website Traffic",
          },
          {
            countNum: 1500,
            prefix: "",
            suffix: "",
            subHeading: "Decrease In Cost Per Acquisition",
          },
        ],
      },
      {
        imgUrl: CaseStudyImg4,
        title: "Bevilles",
        infoLst: [
          {
            countNum: 41,
            prefix: "",
            suffix: "%",
            subHeading: "Increase In Revenue",
          },
          {
            countNum: 22,
            prefix: "",
            suffix: "%",
            subHeading: "Increase In Website Traffic",
          },
          {
            countNum: 35700,
            prefix: "",
            suffix: "",
            subHeading: "New Customers",
          },
          {
            countNum: 27,
            prefix: "",
            suffix: "%",
            subHeading: "Increase In Retail Footfall",
          },
        ],
      },
    ]

    return (
      <section className="case-study-ss">
        <div className="ld-container">
          <div className="case-study-wrap">
            <h2>Case Studies</h2>
            <div className="description">
              <p>
                <b>We work with brands that challenge.</b>
              </p>

              <p>
                They expect accountable, commercially driven results from their
                media and <b>never settle for the status quo.</b>
              </p>
            </div>

            <div className="case-study-lst">
              {caseStudyLst.map((caseStudy, index) => (
                <CaseStudyItem key={index} caseStudy={caseStudy} />
              ))}
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default CaseStudyOrangeSection
