import React from "react"
import { Helmet } from "react-helmet"
import Header from "../components/landing-pages/blocks/header.js"
import CtaItem from "../components/landing-pages/blocks/cta.js"
import HeroOrangeSection from "../components/landing-pages/hero-orange-section.js"
import CaseStudyOrangeSection from "../components/landing-pages/case-study-orange-section.js"
import ImpactfulSection from "../components/landing-pages/impactful-conversions.js"
import PartnersSection from "../components/landing-pages/partners-section.js"
import WhyNextCoSection from "../components/landing-pages/why-nextco-section.js"
import IntroPrometheusSection from "../components/landing-pages/introducing-prometheus.js"
import FAQSection from "../components/landing-pages/faq-section.js"
import FormSection from "../components/landing-pages/form-orange-section.js"

import "../scss/landing.scss"

export default function PaidDigitalPage({ data }) {
  const metadescription =
    "Next&Co are an independent digital and traditional performance marketing agency, visit our site or call 1300 191 950"
  const metatitle = "Paid Media - Next&Co"
  const metaurl = typeof window !== "undefined" ? window.location.href : ""

  const faqs = [
    {
      question: "What media channels are best for my brand?",
      answer:
        "We can help you answer this question. We are big on audience research and can help you determine what an evidence-based media plan actually looks like.",
    },
    {
      question: "What channels can you book paid media on?",
      answer:
        "We book all channels for your brand.  We are channel agnostic and will leverage an audience-first buying strategy that will look at your audience’s media consumption habits and attempt to get your brand in front of them in the right moments.",
    },
    {
      question: "How do you measure media effectiveness?",
      answer:
        "We will work with you to establish a set of key performance indicators for your media spend that corelate to the growth metrics for your brand.  We will then implement a suite of relevant tools to capture these metrics on a continual basis to facilitate ongoing improvement.",
    },
    {
      question: "How does paid media get priced?",
      answer:
        "<p>Paid media can be bought on various models including:</p><ul><li>CPM (cost per mille — Latin for “thousands”)</li><li>CPC (cost-per-click)</li><li>CPV (cost-per-view)</li><li>CPA (cost-per-action)</li></ul><p>We will work with you to determine the best model your media plans objectives.</p>",
    },
    {
      question: "Can you help with creative?",
      answer: "Yes - we can help you with your creative.",
    },
    {
      question:
        "Can you benchmark my existing spend and compare it to my industry and competitors?",
      answer:
        "Yes. We have access to several tools (both proprietary and third party) that can help you get a better understanding of how effective your current media performance is.  Just get in touch with us and request a ‘media benchmark and audit’ and we will be happy to help.",
    },
  ]

  return (
    <>
      <Helmet>
        <title>{metatitle}</title>
        <meta name="description" content={metadescription} />
        <meta name="title" content={metatitle} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={metaurl} />
        <meta property="og:title" content={metatitle} />
        <meta property="og:description" content={metadescription} />
        <meta property="og:image" content="facebook.png" />
        <meta property="twitter:url" content={metaurl} />
        <meta property="twitter:title" content={metatitle} />
        <meta property="twitter:description" content={metadescription} />
        <meta property="twitter:image" content="twitter.png" />
      </Helmet>

      <div id="nd-ld-page" className="nc-landing-page orange">
        <Header />

        <HeroOrangeSection />
        <CaseStudyOrangeSection />
        <CtaItem
          heading="Get Measurably Better™ performance from your paid media"
          ctaLink="/contact"
          ctaText="LET’S TALK"
        />
        <ImpactfulSection />
        <PartnersSection />
        <CtaItem
          heading="Get Measurably Better™ performance from your paid media"
          ctaLink="/contact"
          ctaText="LET’S TALK"
        />
        <WhyNextCoSection />
        <div className="bottom-bg">
          <IntroPrometheusSection />
          <FAQSection faqs={faqs} />
        </div>
        <FormSection heading="Get Measurably Better™ performance from your paid media" />
      </div>
    </>
  )
}
