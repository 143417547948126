import React from "react"
import PaidMediaForm from "./blocks/paid-media-form"

class HeroOrangeSection extends React.Component {
  render() {
    return (
      <section className="hero-orange-ss">
        <div className="ld-container">
          <div className="hero-wrap">
            <div className="left-col">
              <h1>Get Measurably Better™ performance from your paid media.</h1>
              <p className="desc">
                Talk to us about eliminating wasted ad spend and increasing
                ROAS.
              </p>
            </div>

            <div className="right-col">
              <PaidMediaForm submitBtnText="LET’S TALK" />
            </div>
          </div>
          <div className="mobile-desc">
            <p>
              Talk to us about eliminating wasted ad spend and increasing ROAS.
            </p>
          </div>
        </div>
      </section>
    )
  }
}

export default HeroOrangeSection
