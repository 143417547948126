import React from "react"
import IntroPrometheusImg from "../../images/landing-pages/intro-prometheus-ss-img.png"
import TextImage from "../../components/landing-pages/blocks/text-image.js"

class IntroPrometheusSection extends React.Component {
  render() {
    return (
      <section className="intro-prometheus-ss">
        <div className="ld-container">
          <TextImage
            imgUrl={IntroPrometheusImg}
            heading="Introducing Prometheus™ - our proprietary media effectiveness tool"
          >
            <p>
              Prometheus leverages <b>AI-driven</b> media wastage and scale
              calculations and is baked into our media management process. It
              allows for <b>continuous optimisation</b> of unutilized and spend
              and the identification of <b>scale opportunities</b> at a
              audience, channel or messaging level.
            </p>
            <p>
              In other words, your paid media gets{" "}
              <b>better performance day by day.</b>
            </p>
          </TextImage>
        </div>
      </section>
    )
  }
}

export default IntroPrometheusSection
