import React from "react"
import WhyNextCoImg from "../../images/landing-pages/why-nextco-ss-img.png"
import TextImage from "../../components/landing-pages/blocks/text-image.js"

class WhyNextCoSection extends React.Component {
  render() {
    return (
      <section className="why-nextco-ss">
        <div className="ld-container">
          <TextImage imgUrl={WhyNextCoImg}>
            <h2>Why Next&Co?</h2>
            <p>
              We measure the success of your marketing by its impact on your
              actual business and brand with advanced attribution modelling &
              media effectiveness metrics.
            </p>
            <p>
              Deep, data-informed insights. We leverage robust third party &
              proprietary tech to best plan, optimise campaigns, and predict
              future opportunities.
            </p>
            <p>
              Our team thrive on solving hard problems. Our commercially savvy
              media managers and strategists will help your brand find an edge.
            </p>
          </TextImage>
        </div>
      </section>
    )
  }
}

export default WhyNextCoSection
